import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2023/luth-gets-new-cmd/03.jpeg';
// import blogd2 from '../../assets/images/2023/luth-gets-new-cmd/02.jpeg';
// import blogd3 from '../../assets/images/2022/nurse-ndidi-retire/3.jpg';
// import blogd4 from '../../assets/images/2022/nurse-ndidi-retire/4.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Invitation to the The Annual LUTH Scientific Conference
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd1} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  You are cordially invited to : <br />
                  <br />
                  1. The Annual LUTH Scientific Conference themed Cardiovascular
                  disease and Life style coming up on the 15th of June 2023.
                  There will be a rich keynote lecture. Submitted abstracts
                  displayed as posters and a rich symposium. It will be a hybrid
                  conference. Do mark your calendars to attend.
                  <br />
                  <br />
                  2. The Pre-conference Clinical Governance Workshop on the 14th
                  June 2023: tagged Professionalism and Ethics in Healthcare
                  Delivery. Attendance is free but registration is required.
                  Limited spaces available for the workshop. Link to register -
                  <a>https://forms.gle/vfruabF9e44EBzSy9</a> Registration for
                  workshop closes at 9am onTeusday 13th June 2023.
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    <img src={blogd3} height={370}/>
                    <p>
                      Prof Adeyemo, 54, specialises in Oral and Maxillofacial
                      Surgery at the College of Medicine, University of Lagos
                      and is a Consultant Oral and Maxillofacial Surgeon in the
                      Lagos University Teaching Hospital.
                      <br />
                      <br />
                      He has served as the Chairman, Medical Advisory Committee,
                      CMAC, at the Hospital, responsible for Clinical Services
                      and Training. Prior to becoming the CMAC, he was Deputy
                      CMAC, Research and Training for four years (2014-2018) and
                      Chairman of the Security Committee of both Lagos
                      University Teaching Hospital and College of Medicine,
                      University of Lagos, between 2008 and 2014.
                    </p>
                  </div>
                </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Nurse Ndidi Enuwa Retires
                </span> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  He has been on the management board of Lagos University
                  Teaching Hospital (2019 till date); member of the Court of
                  Governors of the College of Medicine University of Lagos, 2021
                  till date and a Member of the Senate of the University of
                  Lagos. We wish him continued success and impact as he takes up
                  the assignment of steering the affairs of the Hospital.
                </p> */}
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div> */}
                {/* <div className='share-block'> */}
                <div className='left-block'>
                  <p>
                    Courtesy: <a href='#none'> Conference planning Committee</a>
                  </p>
                </div>
                {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='INVITATION  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
